import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Link } from 'gatsby';

import BlogLayout from '../components/BlogLayout';
import SEO from '../components/seo';
import { COLORS } from '../styles/constants';
import CardImage from '../components/CardImage';

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  actionButton: {
    backgroundColor: COLORS.plainice,
    '&:hover': {
      backgroundColor: '#1a86db',
    },
  },
  productImage: {
    maxHeight: 277,
  },
  productLink: {
    textDecoration: 'none',
  },
}));

const products = [
  {
    name: 'Pushback',
    link: 'https://pushback.io',
    external: true,
    img: 'pushback.png',
    desc: 'Send push notifications without making an app!',
  },
  {
    name: 'Goalie',
    link: '/goalie',
    external: false,
    img: 'goalie.png',
    desc: 'Habit tracker for the absent minded',
  },
  {
    name: 'Tellspin',
    link: 'https://tellspin.app',
    external: true,
    img: 'tellspin.png',
    desc: 'Automatic at-mention rotation for slack',
  },
];

export default function IndexPage() {
  const classes = useStyles();

  return (
    <BlogLayout>
      <SEO
        title={'Cool refreshing software'}
        keywords={['Software', 'Push notifications', 'Habit tracker']}
      />
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="sm">
            <Typography
              component="h1"
              variant="h2"
              align="center"
              color="textPrimary"
              gutterBottom
            >
              PlainIce
            </Typography>
            <Typography
              variant="h5"
              align="center"
              color="textSecondary"
              paragraph
            >
              Cool refreshing software.
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                  <Button
                    className={classes.actionButton}
                    component={Link}
                    to="/blog"
                    variant="contained"
                    color="primary"
                  >
                    Checkout our blog!
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {products.map(product => {
              let card = (
                <Card className={classes.card}>
                  {product.img && (
                    <CardImage
                      className={classes.productImage}
                      src={product.img}
                    />
                  )}
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {product.name}
                    </Typography>
                    <Typography>{product.desc}</Typography>
                  </CardContent>
                </Card>
              );
              return (
                <Grid item key={product.name} xs={12} sm={6} md={4}>
                  {product.external ? (
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.productLink}
                      href={product.link}
                    >
                      {card}
                    </a>
                  ) : (
                    <Link to={product.link}>{card}</Link>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </main>
    </BlogLayout>
  );
}
